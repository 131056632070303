import { storyblokInit } from 'gatsby-source-storyblok';
// import Hero from "~/components/storyblok/nested/Hero";
// import HeroWithText from "~/components/storyblok/nested/HeroWithText";
// import History from "~/components/storyblok/nested/History";
// import GermanyMap from "~/components/storyblok/nested/GermanyMap";
// import ContactForm from "~/components/storyblok/nested/ContactForm";
// import RichText from "~/components/storyblok/nested/RichText";
// import SplitPanesStories from "~/components/storyblok/nested/SplitPanesStories";
// import ReferencesTiles from "~/components/storyblok/nested/references_tiles";
// import Spacer from "~/components/storyblok/nested/Spacer";
import configuration from '../../../gatsby-config';

const sbConfig = configuration.plugins.find((item) => item.resolve === 'gatsby-source-storyblok');

(() => {
  storyblokInit({
    accessToken: sbConfig.options.accessToken,
    cache: {
      clear: 'auto',
      type: 'memory',
    },
    resolveRelations: [
      'blogpost.author',
    ],
    components: {
      // 'Hero': Hero,
      // 'Hero With Text': HeroWithText,
      // 'History': History,
      // 'Germany Map': GermanyMap,
      // 'Contact Form': ContactForm,
      // 'Rich Text': RichText,
      // 'Split Panes Stories': SplitPanesStories,
      // 'references_tiles': ReferencesTiles,
      // 'spacer': Spacer,
    },
    localAssets: false,
  });
})();
