import React from 'react';

const defaultContextValue = {
  data: {
    fullWidth: true,
    pageTitle: '',
  },
  set: () => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

export class LayoutProvider extends React.Component {
  constructor() {
    super();
    this.setData = this.setData.bind(this);
    this.state = {
      ...defaultContextValue,
      // eslint-disable-next-line react/no-unused-state
      set: this.setData,
    };
  }

  setData(newData) {
    setTimeout(() => {
      const { data } = this.state;
      if (JSON.stringify({
        ...data,
        ...newData,
      }) !== JSON.stringify(data)) {
        this.setState({
          data: {
            ...data,
            ...newData,
          },
        });
      }
    });
  }

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export default Consumer;
