/**
 * taken from https://gist.github.com/codeguy/6684588?permalink_comment_id=3426313#gistcomment-3426313
 */
export const slugify = (string, separator = '-') => {
  if (!string) return '';
  return string
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator);
};

export const isBrowser = () => typeof window !== 'undefined';

const SCRIPTS_LOADED = {};
export const loadExternalScript = (src, options) => {
  const isScriptLoaded = SCRIPTS_LOADED[src];


  if (isScriptLoaded && !options?.force) {
    return isScriptLoaded;
  }

  const promise = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    if (options?.module) {
      script.type = 'text/partytown';
    }
    if (options?.defer) {
      script.defer = true;
    }
    script.src = src;
    script.onload = () => {
      resolve(true);

    };
    script.onerror = (error) => {
      reject(error);
    };
    if (options?.in === 'head') {
      document.head.appendChild(script);
    } else {
      document.body.appendChild(script);
    }
  });
  SCRIPTS_LOADED[src] = promise;
  return promise;
};

export const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const {
    top, left, bottom, right,
  } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight)
        || (bottom > 0 && bottom < innerHeight))
        && ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export function deleteCookie(name) {
  if (!isBrowser()) return;
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function getCookie(cname) {
  if (!isBrowser()) return '';

  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
