import React, { createContext, useCallback } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = React.useState([]);

  const createId = useCallback(() => {
    return Math.random().toString(36).substr(2, 9);
  }, []);

  const openModal = useCallback(({id,body}) => {
    setModals(prevState => {
      return [...prevState, {id,body}]
    })
  }, []);

  const closeModal = useCallback((id) => {
    setModals(prevState => {
      return prevState.filter(modal => modal.id !== id)
    })
  }, []);

  return (
    <ModalContext.Provider value={{ modals, createId, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
}

export const useModals = () => React.useContext(ModalContext);