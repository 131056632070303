import * as React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { CookieNotice } from 'gatsby-cookie-notice';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../assets/logo.svg';
import Footer from './footer';
import ServicesIcon from '../assets/services-icon.svg';
import ReferencesIcon from '../assets/references-icon.svg';
import UseCasesIcon from '../assets/use-cases-icon.svg';
import AboutUsIcon from '../assets/about-us-icon.svg';
import JobsIcon from '../assets/jobs-icon.svg';
import ProductsIcon from '../assets/products-icon.svg';
import BlogIcon from '../assets/blog-icon.svg';
// import SearchIcon from '../assets/search-icon.svg';
import ContactIcon from '../assets/contact-icon.svg';
import ChevronIcon from '../assets/chevron-icon.svg';
import LayoutConsumer, { LayoutProvider } from './layout-context';
import { isBrowser } from '../lib/utils';
import './storyblok/StoryblokBridge';
import Modals from './modals';
import { ModalProvider } from '../contexts/ModalContext';
import * as icons from '../icons/icon-pack';

// useWindowSize Hook
export function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize, { passive: true });
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

function Layout({ children }) {
  library.add({ ...icons });
  const inIframe = React.useMemo(() => {
    try {
      if (typeof window !== 'undefined') {
        return window.self !== window.top;
      }
      return false;
    } catch (e) {
      return true;
    }
  }, []);

  const siteData = useStaticQuery(graphql`
    query SiteMetadataQuery {
      site {
        siteMetadata {
          title
          consent {
            description
          }
        }
      }
    }
  `);

  // eslint-disable-next-line max-len
  const [isNavOpen, setIsNavOpen] = React.useReducer((state) => !state, false); // initiate isNavOpen state with false
  // eslint-disable-next-line no-unused-vars
  const [isNavOpenDesktop, setIsNavOpenDesktop] = React.useState(() => {
    if (typeof localStorage === 'undefined') {
      return true;
    }
    return localStorage.getItem('menuOpen') !== 'false';
  });

  const windowSize = useWindowSize();

  const links = [
    { label: 'Leistungen', url: '/services', icon: (<ServicesIcon className="h-8 w-8" />) },
    { label: 'Referenzen', url: '/references', icon: (<ReferencesIcon className="h-8 w-8" />) },
    { label: 'Use Cases', url: '/use-cases', icon: (<UseCasesIcon className="h-8 w-8" />) },
    { label: 'Apps', url: '/products', icon: (<ProductsIcon className="h-8 w-8" />) },
    { label: 'Über Uns', url: '/about-us', icon: (<AboutUsIcon className="h-8 w-8" />) },
    { label: 'Jobs', url: '/jobs', icon: (<JobsIcon className="h-8 w-8" />) },
    { label: 'Blog', url: '/blog', icon: (<BlogIcon className="h-8 w-8" />) },
    // { label: 'Search', url: '/search', icon: (<SearchIcon className="h-6 w-8" />) },
    { label: 'Kontakt', url: '/contact', icon: (<ContactIcon className="h-8 w-8" />) },
  ];

  const handleDesktopNavToggle = () => {
    setIsNavOpenDesktop((prev) => {
      let _prev = prev;
      if (_prev == null) {
        _prev = document.body.classList.contains('desktop-menu-open');
      }
      window.__setMenuOpen(!_prev);
      return !_prev;
    });

    // conversion tracking Google Analytics
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'collapse_sidebar');
    }
  };

  const handleNavToggle = () => {
    if (windowSize.width >= 1473) return;
    setIsNavOpen((prev) => !prev);
    window.__setBodyScrollLock(!isNavOpen);
  };

  return (
    <LayoutProvider>
      <ModalProvider>
        <LayoutConsumer>
          {({ data }) => (
            <>
              <div className="dark">
                <div id="ProvenExpert_widgetbar_container" />
                <nav
                  role="navigation"
                  className="menu transition-width group z-50 fixed shadow-md xll:shadow-none p-4 xll:p-0 xll:pt-16 flex items-center justify-between w-full xll:h-full xll:block dark:text-white bg-gradient-to-b dark:from-[#050505] dark:to-[#171717] xll:bg-gradient-to-r from-[#FFFFFF] to-[#DDDDDD] dark:xll:from-[#050505] dark:xll:to-[#001C0A] xll:overflow-y-scroll no-scrollbar"
                >
                  <Link to="/" className="flex flex-col justify-center items-center w-fit xll:m-auto">
                    <Logo className="logo transition-all h-8" />
                    <span className="text-sm hidden xll:block mt-1 transition-all">{siteData.site.siteMetadata.title}</span>
                  </Link>
                  <Link to="/" className="text-xl xll:hidden z-0 relative sm:text-2xl left-4">{siteData.site.siteMetadata.title}</Link>
                  <div className="flex">
                    <Link to="/contact" className="text-2xl xll:hidden z-0 relative right-4">
                      <FontAwesomeIcon icon={icon(icons.faMessages)} />
                    </Link>
                    <div className="menu-container transition-all h-[calc(100%-5rem)] xll:flex justify-between flex-col min-w-[32px]">
                      <div>
                        <div
                          aria-expanded={isNavOpen}
                          role="button"
                          className="cursor-pointer flex xll:hidden z-10 top-5 right-4 fixed"
                          onClick={handleNavToggle}
                          onKeyPress={handleNavToggle}
                          tabIndex="0"
                        >
                          <div id="mobile-menu-toggle" className={isNavOpen ? 'open' : ''}>
                            <span />
                            <span />
                            <span />
                            <span />
                          </div>
                        </div>
                        <div
                          className={`fixed xll:top-16 xll:left-0 w-full xll:w-full h-full xll:h-full xll:block xll:static transition-all overflow-hidden overflow-y-scroll xll:overflow-visible xll:bg-transparent dark:xll:bg-transparent pt-12 xll:pt-0 top-0 right-0 before:content-[attr(before)] before:absolute before:w-full before:right-0 before:h-full before:bg-gradient-to-r from-[#FFFFFF] to-[#DDDDDD] dark:from-[#050505] dark:to-[#001C0A] before:transition-all before:duration-200 xll:opacity-100 xll:pointer-events-auto before:xll:static ${isNavOpen ? 'opacity-100 before:top-0 before:right-0 pointer-events-auto h-full' : 'opacity-0 before:!-top-full before:!-right-full pointer-events-none'}`}
                        >
                          <div>
                            <ul className="lg:text-left lg:mt-8 leading-10 flex flex-col gap-4 lg:gap-8 lg:w-fit px-4 lg:px-6 py-4 lg:py-12">
                              {links.map((link, i) => (
                                <li key={link.label} className="relative">
                                  <Link
                                    className={`text-lg lg:text-xl before:content-[attr(before)] before:absolute before:w-0 before:transition-width before:bg-latori-green-light before:dark:bg-latori-green-dark before:-right-4 lg:before:-left-16 before:z-0 before:rounded-l-3xl lg:before:rounded-l-none lg:before:rounded-r-3xl before:h-[calc(100%+1rem)] before:top-[-0.5rem] ${i + 1 === links.length ? 'before:dark:bg-latori-pink before:w-[calc(100%+1rem)] before:lg:w-[calc(100%+5rem)]' : ''}`}
                                    before=" "
                                    activeClassName="before:w-[calc(100%+1rem)] before:xll:w-[calc(100%+5rem)]"
                                    to={link.url}
                                    onClick={() => setTimeout(() => handleNavToggle(), 400)}
                                    partiallyActive
                                    title={link.label}
                                  >
                                    <div className="relative flex flex-row items-center gap-4 justify-end xll:justify-start">
                                      {link.icon}
                                      <div className="menu-label xll:hidden -order-1 xll:order-2 whitespace-nowrap">{link.label}</div>
                                    </div>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="menu-chevron transform-gpu fixed top-1/2 left-0 translate-x-[calc(100px-2rem)] -translate-y-1/2 hidden xll:block transition-all">
                      <ChevronIcon onClick={handleDesktopNavToggle} className="h-8 w-8 cursor-pointer transition-transform duration-300" />
                    </div>
                  </div>
                </nav>
              </div>

              <main className="main transform-gpu pt-16 lg:pt-0 bg:white dark:bg-background dark:text-white transition-all min-h-[80vh]">
                {data.fullWidth
                  ? <div>{children}</div>
                  : (
                    <div className="container px-4 xll:px-8 flex">
                      <div className="mx-auto max-w-screen-lg my-4 xll:my-16">
                        {children}
                      </div>
                    </div>
                  )}
              </main>
              <div className="dark">
                <Footer className="footer transform-gpu text-white" />
              </div>

              {isBrowser() && !window.location.search.includes('_storyblok_tk[token]=') && !inIframe && (
              <CookieNotice
                backgroundWrapperClasses="cookie-notice fixed top-0 left-0 w-full h-full z-[999999] bg-[rgba(255,255,255,0.75)] backdrop-blur text-white md:flex md:justify-center md:items-center"
                backgroundClasses="bg-black absolute md:static bottom-4 left-4 w-[calc(100%-2rem)] p-4 md:p-8 rounded-md shadow-lg md:w-full md:max-w-[600px] max-h-[calc(100vh-2rem)] overflow-x-scroll"
                wrapperClasses="flex md:gap-4 flex-col md:flex-row justify-center align-center text-center md:justify-between"
                personalizeButtonClasses="text-latori-green hover:bg-gray-100 text-xs font-monospace uppercase w-fit rounded p-2 mx-auto"
                personalizeButtonText="Details anzeigen"
                acceptButtonText="Alle akzeptieren"
                acceptButtonClasses="bg-latori-pink hover:bg-latori-green text-white px-[20px] py-[10px] font-monospace uppercase rounded"
                declineButtonText="Alle ablehnen"
                declineButtonClasses="text-latori-green hover:bg-gray-100 px-[20px] py-[10px] mb-2 md:mb-0 md:mr-4 font-monospace uppercase rounded"
                personalizeValidationText="Speichern"
                personalizeValidationClasses="bg-latori-pink hover:bg-latori-green text-white px-[20px] py-[10px] font-monospace uppercase rounded"
                cookieListClasses="flex flex-col gap-4 mt-4"
                cookieMapClasses="form-control text-sm md:text-base"
                cookieTitleClasses="text-lg font-bold"
                cookies={[
                  {
                    name: 'necessary', editable: false, default: true, title: 'Essenziell', text: 'Diese Technologien sind erforderlich, um die Kernfunktionalität der Webseite zu aktivieren.',
                  },
                  {
                    name: 'gatsby-gdpr-google-analytics', editable: true, default: typeof localStorage !== 'undefined' && localStorage?.getItem('gatsby-gdpr-google-analytics') === 'true', title: 'Google Analytics', text: 'Wir nutzen Google Analytics um zu verstehen was auf unserer Webseite passiert. Google Analytics ist ein Analyse Tool von Google, das es ermöglicht, die Besucherzahlen der Website zu messen.',
                  },
                  {
                    name: 'gatsby-gdpr-klaviyo', editable: true, default: typeof localStorage !== 'undefined' && localStorage?.getItem('gatsby-gdpr-klaviyo') === 'true', title: 'Klaviyo', text: 'Wir nutzen Klaviyo, um Newsletteranmeldungen zu ermöglichen.',
                  },
                  {
                    name: 'gatsby-gdpr-proven-expert', editable: true, default: typeof localStorage !== 'undefined' && localStorage?.getItem('gatsby-gdpr-proven-expert') === 'true', title: 'ProvenExpert', text: 'Wir nutzen ProvenExpert um Bewertungen einzuholen und auf unserer Webseite anzuzeigen.',
                  },
                  {
                    name: 'gatsby-gdpr-linked-in', editable: true, default: typeof localStorage !== 'undefined' && localStorage?.getItem('gatsby-gdpr-linked-in') === 'true', title: 'LinkedIn', text: 'Wir nutzen LinkedIn um zu verstehen was auf unserer Website passiert.',
                  },
                  {
                    name: 'gatsby-gdpr-facebook-pixel', editable: true, default: typeof localStorage !== 'undefined' && localStorage?.getItem('gatsby-gdpr-facebook-pixel') === 'true', title: 'Facebook Pixel', text: 'Wir nutzen Facebook Pixel um zu verstehen was auf unserer Webseite passiert.',
                  },
                  {
                    name: 'gatsby-gdpr-inspectlet-pixel', editable: true, default: typeof localStorage !== 'undefined' && localStorage?.getItem('gatsby-gdpr-inspectlet-pixel') === 'true', title: 'Inspectlet Pixel', text: 'Zu Analyse- und Optimierungszwecken verwenden wir auf unserer Webseite Inspectlet.',
                  }
                ]}
              >
                <h4 className="text-xl mb-2 font-bold">{siteData.site.siteMetadata.consent.title}</h4>
                <p className="text-sm md:text-base">
                  {siteData.site.siteMetadata.consent.description}
                </p>
                <div className="mx-auto text-center my-4 flex flex-wrap justify-center">
                  <Link className="text-latori-green text-xs py-[10px] font-monospace uppercase mr-4" to="/imprint">{siteData.site.siteMetadata.consent.imprint}</Link>
                  <Link className="text-latori-green text-xs py-[10px] font-monospace uppercase" to="/privacy">{siteData.site.siteMetadata.consent.privacy}</Link>
                </div>
              </CookieNotice>
              )}
              <Link
                to="/blogpost/shop-usability-award"
                className="fixed bottom-12 right-4 xll:bottom-8 xll:right-8 xll:opacity-75 xll:hover:opacity-100 p-2 xll:p-4"
              >
                <img
                  src="/assets/images/logo-shop-usability-award-best-agency.png"
                  alt="Shop Usability Award Winner 2023"
                  loading="lazy"
                  className="w-32 xll:w-48"
                />
              </Link>
              <Modals />
            </>
          )}
        </LayoutConsumer>
      </ModalProvider>
    </LayoutProvider>
  );
}

export default Layout;
