import * as React from 'react'
import { useModals } from '../contexts/ModalContext'

const Modals = ({ data }) => {
  const { modals, closeModal } = useModals();
  
  React.useEffect(()=>{
    if (modals.length > 0) {
      document.body.classList.add('overflow-hidden');
    }else{
      document.body.classList.remove('overflow-hidden');
    }
  },[modals])

  return (
    <div>
      {modals.map(({id,body}) => {
        return (
          <div onClick={()=>{closeModal(id)}} key={id} className="fixed top-0 left-0 w-full h-full z-[999999] bg-[rgba(0,0,0,0.5)] backdrop-blur md:flex md:justify-center md:items-center">
            <div onClick={e=>{e.stopPropagation()}} className="bg-white absolute md:static bottom-4 left-4 h-[calc(100%-2rem)] overflow-auto w-[calc(100%-2rem)] p-4 md:p-8 rounded-md shadow-lg md:h-auto md:w-full md:max-w-[700px] md:max-h-[calc(100vh-2rem)] overflow-x-scroll">
              <div className='relative'>
                <button onClick={()=>{closeModal(id)}} className="fixed z-99 px-4 top-4 right-4 text-black md:text-white text-2xl">&times;</button>
              </div>
              {body}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Modals;
